@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Titan+One&display=swap");

html {
  scroll-behavior: smooth;
}

.font-titan {
  font-family: "Titan One", sans-serif;
}

body {
  background-color: #bd542b;
  color: #fff;
  font-family: "Lilita One", sans-serif;
}

* {
  outline: none;
}

img {
  width: 100%;
}

a {
  cursor: pointer;
  color: inherit;
}

.App {
  overflow: hidden;
}

.custom-slide {
  /* position: absolute; */
  /* top: 0; */
}

/* .custom-slide div.active {
  width: 400px !important;
  overflow: hidden;
} */

.slick-slide {
  opacity: 0;
}

.slick-slide.slick-active {
  opacity: 1;
}

.react-slideshow-wrapper.slide {
  margin-top: -650px;
  margin-left: 140px;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  width: 400px;
  height: 410px;
}

@media screen and (max-width: 768px) {
  .each-slide-effect > div {
  }
}

.w-break {
  word-break: break-word;
}

.each-slide-effect {
  width: 400px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.text-stroke-custom {
  font-family: "Titan One", sans-serif;
  color: white;
  -webkit-text-fill-color: #86bc2f; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
